(function ($) {
    $.fn.reqon = function (options) {
        const settings = $.extend({
            headers: {},
            init: function ($element) { },
            processing: function ($element, status) { },
            confirm: function (message) { return confirm(message); },
            respond: function ($element, json) { },
        }, options);
 
        const $this = $(this);

        const processing = ($element, status) => {
            if (status) {
                $element.addClass('processing');
            } else {
                $element.removeClass('processing');
            }
            settings.processing($element, status);
        };

        const respond = ($element, json) => {

            if (json.jquery) {
                if (json.jquery.element && json.jquery.method) {
                  if (json.jquery.value !== undefined) {
                    if (Array.isArray(json.jquery.value)) {
                      $(json.jquery.element)[json.jquery.method](...json.jquery.value);
                    } else {
                      $(json.jquery.element)[json.jquery.method](json.jquery.value);
                        if(json.jquery.method == 'html' || json.jquery.method == 'append' || json.jquery.method == 'prepend'){
                            init($(json.jquery.element));
                        }
                    }
                  } else {
                    $(json.jquery.element)[json.jquery.method]();
                  }
                } else {
                  $.each(json.jquery, function (index, uiTarget) {
                    if (uiTarget.element && uiTarget.method) {
                      if (uiTarget.value !== undefined) {
                        if (Array.isArray(uiTarget.value)) {
                          $(uiTarget.element)[uiTarget.method](...uiTarget.value);
                        } else {
                          $(uiTarget.element)[uiTarget.method](uiTarget.value);
                          if(uiTarget.method == 'html' || uiTarget.method == 'append' || uiTarget.method == 'prepend'){
                            init($(uiTarget.element));
                          }
                        }
                      } else {
                        $(uiTarget.element)[uiTarget.method]();
                      }
                    }
                  });
                }
            }

            settings.respond($element, json);

            if (json.redirect) {
                window.location.href = json.redirect;
            }

            if (json.reload) {
                location.reload()
            }

            if (json.fn) {
                const { name, arguments: args = [] } = json.fn;
                const func = window[name];

                if (typeof func === 'function') {
                    func(...args);
                } else {
                    console.error(`Function ${name} is not defined`);
                }
            }
        };

        const request = async ($element, action) => {
            processing($element, true);
        
            const confirmMessage = $element.attr('confirm');
            if (confirmMessage) {
                const proceed = await settings.confirm(confirmMessage);
                if (!proceed) {
                    processing($element, false);
                    return;
                }
            }
            
            const url = $element.attr('on-' + action);      
            const urlPattern = /^(http|https):\/\/[^ "]+$/;
            
            if (urlPattern.test(url)) {
                const sendRequest = (retryCount) => {
                    let formData;
        
                    if (action == 'submit') {
                        formData = new FormData($element[0]);
                    } else {
                        formData = new FormData();
                        if($element.val()){
                            formData.append('value', $element.val());
                        }
                        $.each($element[0].attributes, (index, attribute) => {
                            if (attribute.name.startsWith("data-")) {
                                formData.append(attribute.name, attribute.value);
                            }
                        });
                    }
            
                    $.ajax({
                        url: url,
                        headers: settings.headers,
                        method: 'POST',
                        processData: false,
                        contentType: false,
                        data: formData,
                        cache: false,
                        timeout: 0,
                        success: function(response) {
                            respond($element, response);
                            processing($element, false);
                        },
                        error: function() {
                            if (retryCount > 0) {
                                sendRequest(retryCount - 1);
                            } else {
                                processing($element, false);
                            }
                        }
                    });
                };
            
                sendRequest(0);
            } else {
                console.error("URL is not valid.");
            }
        };

        const init = ($element) => {
            const events = ['load', 'submit', 'change', 'click', 'dblclick', 'blur', 'focus', 'focusin', 'focusout', 'keydown', 'keypress', 'keyup'];

            events.forEach(action => {
                if ($('[on-' + action + ']').length > 0) {
    
                    $element.find('[on-' + action + ']').each(function (event) {
    
                        if (action == 'load') {
                            request($(this), action);
                        }
    
                        $(this).on(action, function (event) {
                            event.preventDefault();
                            request($(this), action);
                        });
                    });
                }
            });

            settings.init($element);

            return $element;
        };

        return init($this);
    };
})(jQuery);