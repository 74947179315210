import 'bootstrap';
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'select2';
import feather from 'feather-icons';
feather.replace();
import Swal from 'sweetalert2';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

require('./reqon');

$(document).ready(function () {

  var config = {
    headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    },
    init: function ($element) {
      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]', $element))
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      });

      $('.select2', $element).each(function () {
        let form = $(this).closest('form');
        $(this).select2({ dropdownParent: form.length ? form : $('body') });
      });

      $('[data-toggle="tooltip"]', $element).tooltip();

      $('.nav-open-btn', $element).on('click', function(){
          $('body').addClass('open-navbar');
      })
      $('.overlay, .nav-close-btn', $element).on('click', function(){
          $('body').removeClass('open-navbar');
      })

    },
    processing: function ($element, status) {
        $element.find('button[type="submit"]').prop('disabled', status);

        if (status) {
          $('[loading]').attr('loading', 'true');
        } else {
          $('[loading]').attr('loading', 'false');
        }
    },
    confirm: async function (message) {

      try {
        const result = await Swal.fire({
          title: 'Are you sure?',
          text: message,
          icon: 'warning',
          showCancelButton: true,
          allowOutsideClick: () => !Swal.isLoading(),
          confirmButtonColor: '#757575',
          cancelButtonText: 'Cancel',
          cancelButtonColor: '#b40606',
          confirmButtonText: 'Yes, Proceed',
        });

        if (typeof result.isConfirmed === 'undefined') {
          return false;
        }

        return result.isConfirmed;
      } catch (error) {
        console.error('Error in confirm dialog:', error);
        return false;
      }

    },
    respond: function ($element, json) {
      if (json.errors) {
          $('.is-invalid').removeClass('is-invalid');
          $('.invalid-feedback').remove();
          $.each(json.errors, function (name, errors) {
              var nameSplit = name.split('.');
              var field = nameSplit.length == 1 ? name : (nameSplit[0] + '[' + nameSplit.splice(1).join('][') + ']');
              $("[name='" + field + "']", $element).addClass("is-invalid");
              $("[name='" + field + "']", $element).closest('div').append( $('<div/>').addClass('invalid-feedback').html(errors[0]));
              $("[name='" + field + "']", $element).closest('div').find('.invalid-feedback').show();
              $("[name='" + field + "']", $element).on('change', function(){
                  $(this).removeClass('is-invalid').closest('div').find('.invalid-feedback').remove();
              });
          });

          if ($('.invalid-feedback')[0]){
              $("html,body").animate({scrollTop: $('.invalid-feedback').offset().top - 80, scrollLeft: 0},300);
          } 
      }
 
      if (json.modal) {
        if (json.modal.hide) {
          $(json.modal.hide).modal('hide');
        }
        if (json.modal.show) {
          $(json.modal.show).modal('show');
        }
      }

      if (json.alert) {
        let swalconfig = {
          position: 'center',
          showConfirmButton: false,
          timer: 2000,
        }

        Object.assign(swalconfig, json.alert)

        Swal.fire(swalconfig).then(function (result) {
          if (json.alert.redirect) {
            window.location.href = json.alert.redirect;
          }
        });
      }

      if (json.reset) {
        const $form = $element.closest('form');
        if ($form.length > 0) {
          $form[0].reset(); $('select').trigger('change');
        }
      }
    }
  }
 
  $('html').reqon(config);

  function checkScreenWidth() {
    if ($(window).width() < 768) {
        $('#date-display').appendTo('.date-wraper');
    } else {
        $('#date-display').appendTo('.date-wraper-real');
    }
  }

  checkScreenWidth();

  $(window).resize(function() {
      checkScreenWidth();
  });
});


// show/hide password
$('.toggle-password').click(function() { 
  var $input = $(this).siblings('input'); 
  if ($input.attr('type') === 'password') {
      $input.attr('type', 'text');
  } else {
      $input.attr('type', 'password');
  }
  $(this).toggleClass('active');
});